.App {
  padding-top: 100px;
  padding-bottom: 100px;
  text-align: center;
  color: white;
  min-height: 100vh;
}

.App-content {
  text-align: left;
}
.App-link {
  color: #09d3ac;
}

.sm-line-break {
  height: 50px;
}

.page-title {
  text-align: center;
}

.custom-button {
  color: #0b4592;
  background-color: #88e3fe;
  border-radius: 30px;
  border: 0px;
  box-shadow: inset 0px 3px 1px #033260;
  padding: 0.375em 2.5em;
  font-weight: 500;
}

.custom-button:hover,
.custom-button:not(:disabled):not(.disabled).active,
.custom-button:not(:disabled):not(.disabled):active {
  box-shadow: inset 0 0 0;
  color: #0b4592;
  background-color: #88e3fe;
}

.custom-button.disabled,
.custom-button:disabled {
  color: #0b4592;
  background-color: #88e3fe;
  opacity: 0.35;
}

.custom-button.disabled:hover,
.custom-button:disabled:hover {
  box-shadow: inset 0px 3px 1px #033260;
  cursor: default;
}

.error {
  color: red;
}
