.hash-modal {
	padding: 16px;
}

textarea.unhashedData-textarea,
textarea.hashedData-textarea {
  height: 300px;
  resize: none;
}

.custom-file-upload {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.custom-file-upload + label {
    display: inline-block;
}

.custom-file-upload + label {
	cursor: pointer; /* "hand" cursor */
}

.custom-file-upload:focus + label {
	outline: 1px dotted #000;
	outline: -webkit-focus-ring-color auto 5px;
}

.arrow-background{
	padding-top: 50px;
	padding-bottom: 35px;
}

.arrow-text{
	color: #a1e9ff;
	margin-left: 15%;
    margin-top: -10%;
}