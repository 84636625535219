.tnc-area {
  height: 400px;
  width: 100%;
  padding-bottom: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
}

textarea.encrypt-textarea {
  height: 400px;
  overflow-y: scroll;
  resize: none;
}

.custom-checkbox .form-check-input:disabled ~ .form-check-label {
  color: #a4afb8;
}
